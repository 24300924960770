<template>
    <div class="pack-el-form" v-if="formConfig.length > 0" :style="alignStyle">
        <el-form :model="params" :rules="rules" ref="ruleForm" class="pack-form-box" inline>
            <el-form-item
                v-for="item in formConfig"
                :key="item.label"
                :label="item.label"
                :prop="item.prop"
                label-position="right"
                :label-width="`${labelWidth * 1 + 10}px`"
            >
                <div v-if="item.type == 'img'" class="form-img" :style="inputStyle">
                    <div class="img-box" v-loading="params[item.prop] && !imgOpacity">
                        <div v-if="params[item.prop]" class="clear-img" :style="imgStyle">
                            <span class="clear-button" @click="clearHandler(item)">×</span>
                        </div>
                        <img
                            v-if="params[item.prop]"
                            @load="imgLoadFinish"
                            @error="imgLoadFinish"
                            :style="imgStyle"
                            class="preview-avatar"
                            :src="params[item.prop]"
                            :alt="item.label"
                            :title="item.label"
                        />
                        <el-upload
                            v-else
                            :multiple="false"
                            :action="item.uploadPath"
                            list-type="picture-card"
                            :headers="uploadHeaders"
                            :show-file-list="false"
                            :name="item.field ? item.field : item.prop"
                            :on-success="(...args) => verifySize(item, args)"
                            :on-error="item.uploadErr"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="hint">(建议尺寸;200*200；格式为jpg或jpej或png)</div>
                </div>
                <div v-else-if="item.type == 'select'" class="form-select" :style="inputStyle">
                    <el-select
                        :value="params[item.prop]"
                        :placeholder="item.placeholder ? item.placeholder : '请选择'"
                        @change="(value) => inputChange(value, item.prop)"
                    >
                        <el-option v-for="item in item.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div v-else class="input" :style="inputStyle">
                    <el-input
                        :type="item.type ? item.type : 'text'"
                        :value.sync="params[item.prop]"
                        :placeholder="item.placeholder"
                        autocomplete="off"
                        :maxlength="item.max"
                        ref="formItem"
                        @input="(value) => inputChange(value, item.prop)"
                    ></el-input>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "PackElForm",
    data() {
        return {
            imgLoad: false,
            imgOpacity: 0,
            animeTime: 300,
        };
    },
    components: {},
    props: {
        rules: {
            type: Object,
            default: () => {},
        },
        params: {
            type: Object,
            default: () => {},
        },
        /**
         * normal: label, prop, type, placeholder, max,
         * img: clear, uploadSuccess, field, uploadErr, uploadPatch
         * select: options
         */
        formConfig: {
            type: Array,
            default: () => [],
        },
        inputWidth: {
            type: [Number, String],
            default: 300,
        },
        labelWidth: {
            type: [String, Number],
            default: 65,
        },
        isCenter: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        alignStyle() {
            return {
                alignItems: this.isCenter ? "center" : "start",
            };
        },
        imgStyle() {
            return {
                opacity: this.imgOpacity,
            };
        },
        inputStyle() {
            return {
                width: `${this.inputWidth}px`,
            };
        },
        uploadHeaders() {
            const keys = ["adminToken", "schoolToken", "trainToken", "teacherToken", "studentToken"];
            const role = localStorage.getItem("role") - 1;
            return {
                Authorization: localStorage.getItem(keys[role]) || "",
            };
        },
    },
    methods: {
        inputChange(value, prop) {
            this.$emit("update:params", {
                ...this.params,
                [prop]: value,
            });
        },
        validate(callback) {
            this.$refs.ruleForm.validate((valid) => valid && callback(valid));
        },
        imgLoadFinish() {
            this.imgOpacity = 1;
        },
        clearHandler(item) {
            this.imgOpacity = 0;
            setTimeout(() => item.clear(), this.animeTime);
        },
        verifySize(item, args) {
            const sizeM = args[1].size / 1024 / 1024;
            sizeM <= 2 ? item.uploadSuccess(...args) : this.$message.error("上传的图片大小不能超过2M");
        },
    },
    mounted() {
        const formItems = this.$refs.formItem;
        if (formItems && formItems.length > 0) {
            const first = formItems[0];
            const target = first && first.$el.querySelector(".el-input__inner");
            this.$nextTick(() => target && target.focus());
        }
    },
};
</script>

<style scoped lang="scss">
.pack-el-form {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    .form-img {
        .img-box {
            position: relative;
            width: 100px;
            height: 100px;
            .preview-avatar {
                width: 100%;
                height: 100%;
                z-index: 9;
                transition: all 0.3s;
            }
            .clear-img {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border: 13px solid red;
                border-color: red red transparent transparent;
                transition: all 0.3s;
                .clear-button {
                    position: absolute;
                    right: -11px;
                    top: -11px;
                    font-size: 18px;
                    line-height: 13px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
        .hint {
            margin-top: 10px;
            text-align: start;
            color: #ababbb;
            line-height: normal;
        }
    }
}
::v-deep .pack-form-box {
    .el-form-item {
        flex: 1;
        display: flex;
        margin-right: 0;
        .el-form-item__error {
            margin-left: 14px;
        }
        .el-form-item__label {
            white-space: nowrap;
            color: #1b162a;
        }
        .form-select {
            text-align: start;
            .el-select {
                width: 100%;
            }
            .el-icon-arrow-up:before {
                content: "\e78f";
                color: #342e43;
                font-size: 20px;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    margin-bottom: 0 !important;
}
::v-deep .el-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}
/* ::v-deep .el-input,
::v-deep .el-upload {
    background-color: transparent;
} */
</style>
